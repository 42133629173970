import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// main.js
import '@/common/base.css'; // 使用相对路径或者别名（如果有配置）
import '@/common/footer.css';

createApp(App).use(router).mount('#app')


