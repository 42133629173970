import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../views/Home.vue'
import QrCodePage from '../views/QrCode.vue'

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/download",
    name: "QrCodePage",
    component: QrCodePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  next(); // 确保一定要调用 next()
});
export default router