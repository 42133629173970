<template>
  <div class="header">
    <div class="nav">
      <a class="nav-link cur">首页</a><a class="nav-link" target="_blank" style="color: gray">网页版</a>
    </div>
  </div>

  <div id="myra-page-container">
    <div class="myra-page">
      <div class="download-wrapper">
        <div class="download-content">
          <div class="download-left">
            <h1 class="download-title">下载米拉 App</h1>
            <div class="download-desc">发现你的AI学习伙伴：开启智慧学习之旅。</div>
            <toast ref="toast"></toast>
            <div class="app-icon">
              <a href="/download/app" target="_blank">
                <img src="../assets/cMyRA-logo.png" alt="">
              </a>
            </div>
            <div id="app-container">
              <div id="app-qr-code">
                <div class="img-container">
                  <img src="../assets/qrcode-download.png" alt="">
                </div>
                <div class="dl-scan">手机扫码直接下载</div>
              </div>
              <div class="dl-types column dl-buttons">
                <a class="dl-btn enabled" id="dl-btn-android"
                   href="https://yastai.oss-cn-beijing.aliyuncs.com/android/myra-release_v1.0.0.apk" target="_blank">
                  <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.3828 8.15234C14.8145 8.15234 15.1797 7.82031 15.1797 7.35547C15.1797 6.92383 14.8145 6.55859 14.3828 6.55859C13.9512 6.55859 13.5859 6.92383 13.5859 7.35547C13.5859 7.82031 13.9512 8.15234 14.3828 8.15234ZM5.58398 8.15234C6.01562 8.15234 6.38086 7.82031 6.38086 7.35547C6.38086 6.92383 6.01562 6.55859 5.58398 6.55859C5.15234 6.55859 4.78711 6.92383 4.78711 7.35547C4.78711 7.82031 5.15234 8.15234 5.58398 8.15234ZM14.6816 3.37109C17.4043 4.86523 19.2637 7.62109 19.5625 10.875H0.4375C0.703125 7.62109 2.5625 4.86523 5.28516 3.37109L3.69141 0.615234C3.6582 0.548828 3.6582 0.482422 3.6582 0.416016C3.6582 0.25 3.79102 0.0839844 3.99023 0.0839844C4.12305 0.0839844 4.22266 0.183594 4.28906 0.283203L5.88281 3.07227C7.14453 2.50781 8.53906 2.20898 10 2.20898C11.4609 2.20898 12.8223 2.50781 14.084 3.07227L15.6777 0.283203C15.7441 0.183594 15.8438 0.0839844 15.9766 0.0839844C16.1758 0.0839844 16.3086 0.25 16.3086 0.416016C16.3086 0.482422 16.3086 0.548828 16.2754 0.615234L14.6816 3.37109Z"
                        fill="white"/>
                  </svg>
                  <span>下载 Android 版</span>
                </a>
                <a class="dl-btn dl-btn-ios" id="dl-btn-ios" href="https://itunes.apple.com/cn/app/id6474657872"
                   target="_blank">
                  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.6836 8.05664C10.6836 6.82812 11.248 5.93164 12.3438 5.23438C11.7129 4.33789 10.7832 3.87305 9.55469 3.77344C8.35938 3.67383 7.06445 4.4375 6.59961 4.4375C6.10156 4.4375 4.97266 3.80664 4.07617 3.80664C2.2168 3.83984 0.257812 5.26758 0.257812 8.22266C0.257812 9.08594 0.390625 9.98242 0.722656 10.9121C1.1543 12.1406 2.68164 15.1289 4.27539 15.0625C5.10547 15.0625 5.70312 14.4648 6.79883 14.4648C7.86133 14.4648 8.39258 15.0625 9.32227 15.0625C10.9492 15.0625 12.3438 12.3398 12.7422 11.1113C10.584 10.082 10.6836 8.12305 10.6836 8.05664ZM8.82422 2.61133C9.7207 1.54883 9.62109 0.552734 9.62109 0.1875C8.82422 0.253906 7.89453 0.751953 7.36328 1.34961C6.76562 2.01367 6.43359 2.84375 6.5 3.74023C7.36328 3.80664 8.16016 3.375 8.82422 2.61133Z"
                        fill="white"/>
                  </svg>
                  <span class="ios-dl-text">下载 iOS 版</span>
                </a>
              </div>
            </div>
          </div>
          <div class="download-right">
            <img src="../assets/screenshot.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="basic-footer__1dsX">
    <div class="basic-footer-inner__3rTc">
      <section>
        <div class="site-info__2LIB">
          <a href="https://yastai.com/" target="_blank">© 2024 北京伸个懒腰科技有限公司</a>
          <div class="footer-links__3tis">
            <span class="pop-trigger__39Yk" data-key="business-consulting">商务咨询：help@yastai.com</span>
            <a href="https://yastai.com/myra-ua-new.html" target="_blank">用户协议</a>
            <a href="https://yastai.com/myra-privacy-new.html" target="_blank">隐私政策</a>
          </div>
        </div>
        <div class="site-legal__1oR1">
          <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023012630号-3</a>
          <!--          <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602104940" target="_blank">-->
          <!--            <img src="../assets/beiand0289dc0.png" alt="" style="width: 14px; height: 14px; margin-right: 4px;">京公网安备11010602104940号</a>-->
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
import {ref} from 'vue';
import Toast from '../components/AppToast.vue';

export default {
  name: "HomePage",
  components: {
    Toast
  },
  setup() {
    const toast = ref(null);

    // 检查是否在微信浏览器中
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.includes('micromessenger');
    };

    // 检查是否在移动设备上
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|iphone/.test(userAgent);
    };

    // 下载按钮点击事件
    const onDownloadClick = (event, platform) => {
      if (isWeChatBrowser() && isMobileDevice()) {
        // 如果是微信浏览器，阻止默认行为并显示提示
        event.preventDefault();
        toast.value.displayToast('请点击右上角按钮, 选择使用浏览器打开此页面下载App',3000);
      } else {
        // 非微信浏览器，根据平台进行相关操作（示例中未具体实现）
        if (platform === 'android') {
          // Android下载逻辑
        } else if (platform === 'ios') {
          // iOS下载逻辑
        }
      }
    };

    // 绑定点击事件到下载按钮
    window.onload = () => {
      const androidDownloadButton = document.getElementById('dl-btn-android');
      const iosDownloadButton = document.getElementById('dl-btn-ios');

      androidDownloadButton.addEventListener('click', (event) => onDownloadClick(event, 'android'));
      iosDownloadButton.addEventListener('click', (event) => onDownloadClick(event, 'ios'));
    };

    return {
      toast,
    }
  },
};


</script>

<style>

#myra-page-container {
  background: #f6f6f6;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px; /* 页脚的高度 */
}

input, button, textarea, div, a, span {
  -webkit-tap-highlight-color: #0000;
  -webkit-appearance: none;
  outline: none;
}

.myra-page {
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex
}

.download-wrapper {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 20px 0;
  display: flex;
  /*overflow: auto*/
}

.download-wrapper .download-content {
  justify-content: center;
  align-items: center;
  height: 420px;
  display: flex
}

.download-wrapper .download-left {
  flex-direction: column;
  align-items: center;
  display: flex
}

.download-wrapper .download-right {
  padding: 0 0 0 80px
}

.download-wrapper .download-right img {
  object-fit: contain;
  border-radius: 12px;
  width: 236px;
  height: 420px;
  /*box-shadow: 0 5px 20px #0003*/
}

.download-wrapper .download-title {
  margin: 0;
  font-size: 26px;
  font-weight: 500
}

.download-wrapper .download-desc {
  margin: 30px 0 0;
  font-size: 18px
}

.download-wrapper .back-btn {
  flex-shrink: 0;
  width: 160px;
  margin-top: 50px;
  font-size: 15px
}

.download-wrapper .app-icon {
  border-radius: 12px;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin: 40px 0;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 60px #00010233
}

.download-wrapper .app-icon img {
  width: 80px;
  height: 80px
}

.download-wrapper #app-container {
  align-items: flex-start;
  display: flex
}

.download-wrapper #app-container.active {
  opacity: 1;
  pointer-events: auto
}

.download-wrapper .img-container {
  align-items: center;
  display: flex
}

.download-wrapper #app-qr-code {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  display: flex
}

.download-wrapper #app-qr-code img {
  width: 140px;
  height: 140px;
  margin-bottom: 4px
}

.download-wrapper .dl-btn {
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  width: 200px;
  height: 50px;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  display: flex
}

.download-wrapper .dl-btn svg {
  width: 28px;
  height: 28px;
  margin: 0 8px 0 26px
}

.download-wrapper #dl-btn-android {
  background: #000000
}

.download-wrapper #dl-btn-android:hover {
  background: #000000
}

.download-wrapper #dl-btn-android:active {
  background: #000000
}

.download-wrapper #dl-btn-android span {
  color: #ffffff
}

.download-wrapper .dl-btn-ios {
  color: #fff;
  background: #000
}

.download-wrapper .dl-btn-ios.redirecting {
  justify-content: center;
  width: 210px
}

.download-wrapper .dl-btn-ios.redirecting img {
  margin-left: 0
}

.download-wrapper .dl-btn-ios .ios-dl-text {
  margin-left: 12px
}

.download-wrapper .dl-btn-ios:hover {
  background: #3d3d3d
}

.download-wrapper .dl-btn-ios:active {
  background: #292929
}

.download-wrapper .dl-types {
  margin-top: 10px;
  display: flex
}

.download-wrapper .dl-types.column {
  flex-direction: column
}

.download-wrapper .dl-type {
  margin: 30px 20px
}

.download-wrapper .dl-type > div {
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex
}

.download-wrapper .dl-type img {
  opacity: .6;
  width: 70px;
  height: 70px
}

.download-wrapper .dl-remark {
  text-align: center;
  color: #5f5f64;
  font-size: 15px;
  line-height: 1.8
}

.download-wrapper .dl-remark a {
  color: #08f;
  margin: 0 2px
}

.download-wrapper .dl-buttons {
  justify-content: center;
  align-items: center;
  margin-top: 0;
  display: flex
}

.download-wrapper .dl-gzh {
  margin-top: 10px
}

.download-wrapper .dl-gzh img {
  width: 150px;
  height: 150px
}

.download-wrapper .dl-scan {
  color: #5f5f64;
  font-size: 15px
}

.download-wrapper .dl-aski-remark {
  text-align: center;
  max-width: 680px;
  margin-top: 10%
}

.download-wrapper .dl-aski-remark h2 {
  margin: 0 0 20px;
  font-size: 26px;
  font-weight: 500
}

.download-wrapper .dl-aski-remark div {
  margin-top: 10px;
  font-size: 15px;
  line-height: 1.55
}

.download-wrapper .dl-aski-remark div a {
  color: #686efe;
  font-weight: 500
}

@media screen and (max-width: 10px) {
  .download-wrapper .download-content {
    position: relative
  }

  .download-wrapper .download-left {
    z-index: 1;
    padding-bottom: 50px;
    position: relative
  }

  .download-wrapper .download-right {
    opacity: .15;
    padding-left: 0;
    position: absolute;
    bottom: 0;
    right: -20px;
    transform: rotate(15deg)
  }

  .download-wrapper .download-right img {
    width: 220px
  }
}

@media screen and (max-width: 800px) {
  .download-wrapper .download-content {
    position: relative
  }

  .download-wrapper .download-left {
    z-index: 1;
    padding-bottom: 50px;
    position: relative
  }

  .download-wrapper .download-right {
    opacity: .15;
    padding-left: 0;
    position: absolute;
    bottom: 0;
    right: -20px;
    transform: rotate(15deg)
  }

  .download-wrapper .download-right img {
    width: 220px
  }
}

@media screen and (max-width: 420px) {
  .download-wrapper {
    padding-left: 8px;
    padding-right: 8px
  }

  .download-wrapper #app-qr-code {
    margin-right: 10px
  }

  .download-wrapper .dl-btn {
    width: 180px
  }

  .download-wrapper .dl-btn svg {
    margin-left: 14px
  }
}

.header {
  display: block;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fffc;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
}

.header h1 {
  display: none;
}

.nav {
  width: 100%;
  height: 80px;
  position: relative;
  margin: 0 auto;
}

.nav-link {
  display: block;
  height: 80px;
  line-height: 80px;
  margin-left: 1px;
  padding: 0 40px;
  font-size: 20px;
  color: black;
  text-decoration: none;
  font-weight: normal;
  float: left;
}

.nav-link:hover, .nav-link.cur {
  /*background: #0003;*/
}

.nav-link.scrollc {
  height: 60px;
  line-height: 60px;
}
</style>
