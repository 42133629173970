<script setup>
import { ref, onMounted } from 'vue';
import Toast from '../components/AppToast.vue'; // 确保这个路径与您的项目结构相匹配

const toast = ref(null);

const detectBrowserAndOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isWeChatBrowser = /micromessenger/.test(userAgent);
  const isIOS = /iphone|ipad|mac os|ipod/.test(userAgent);

  // 检查是否在移动设备上
  const isMobileDevice = () => {
    return /android|iphone/.test(userAgent);
  };

  if (isWeChatBrowser && isMobileDevice()) {
    // 如果是微信浏览器，显示提示信息
    toast.value.displayToast("请点击右上角按钮, 选择使用浏览器打开。",20000);
  } else if (isIOS) {
    // 如果是iOS设备，跳转到App Store下载页面
    window.location.href = 'https://itunes.apple.com/cn/app/id6474657872';
  } else {
    // 其他设备，默认下载Android APK
    window.location.href = 'https://yastai.oss-cn-beijing.aliyuncs.com/android/myra-release_v1.0.0.apk';
  }
};

onMounted(() => {
  detectBrowserAndOS();
});
</script>

<template>
  <div>
    <Toast ref="toast" /> <!-- 使用正确的组件标签 -->
    <!-- 模板内容，这里可以放置一个备用的下载指引或其他内容 -->
  </div>
</template>
